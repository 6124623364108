import { Button as AntButton, Col, Drawer, Form, Image, Modal, Popconfirm, Row, Switch, Table, Tooltip, Upload, UploadFile, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { Icon } from 'ts-react-feather-icons';
import { UserRole } from '../../constants/userRole';
import { useUser } from '../../hooks';
import User from '../../model/User';
import CustomPagination from '../CustomPagination';
import { deleteCharacterImage, getCharacterImages, updateCharacterImage, uploadCharacterImages } from '../../helpers/api/story';

interface CharacterImageModel {
    key: React.Key;
    id: string;
    url: string;
    status: string;
    type: string;
    createdAt: Date;
    modifiedAt: Date;
    prompt: string;
    characterId: string;
}

const CharacterImageDrawer = ({
    storyId,
    drawerVisibility,
    setdDrawerVisibility,
    characterDetail,
}: any) => {
    const currentLoggedInUser = useUser().user as User || null;

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);

    const [submittables, setSubmittable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [characterImages, setCharacterImages] = useState<any[]>([]);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const [api, contextHolder] = notification.useNotification();
    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    // for image
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [images, setImages] = useState<UploadFile[]>([]);

    // images
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        const tempFiles: UploadFile<any>[] = [];
        newFileList.forEach(f => {
            const existedFiles = tempFiles.filter(e => e.name === f.name);
            if (existedFiles && existedFiles.length > 0) {
                return;
            }
            tempFiles.push(f);
        });
        setImages(tempFiles);
    }

    const uploadButton = (
        <div>
            {/* <PlusOutlined rev={1} /> */}
            <Icon name="plus" size={16} />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(
                () => { setSubmittable(images.length > 0) },
                () => { setSubmittable(false) },
            );
    }, [formValueObj, images]);

    useEffect(() => {
        if (!characterDetail) {
            form.setFieldValue("status", "inactive");
            return;
        }
        form.setFieldsValue({ ...characterDetail });
        search();
    }, [characterDetail]);

    const onClose = () => {
        form.resetFields();
        setImages([]);
        setSubmittable(false);
    }

    const uploadImages = async () => {
        try {
            form.validateFields();
            setLoadingBtn(true);
            let response;
            const formData = new FormData();
            images.forEach(img => {
                formData.append("images", img.originFileObj as Blob, img.originFileObj?.name);
            });
            response = await uploadCharacterImages(storyId, characterDetail.id, formData);

            setLoadingBtn(false);
            if (response?.status === 200) {
                api.success({
                    message: `Successfull notification`,
                    description: 'Upload character image(s) successfully!',
                    placement: 'topRight'
                });
                setImages([]);
                search();
                return;
            }
        } catch (err) {
            console.log(err);
            showError(err as string);
        }
        setLoadingBtn(false);
    }


    const columns: ColumnsType<CharacterImageModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Image",
            dataIndex: "name",
            render: (text, record, index) => {
                return (
                    <Tooltip title={record.prompt}>
                        <span>
                            <Image
                                width={50}
                                src={record.url}
                            />
                        </span>
                    </Tooltip>
                )
            },
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Published?",
            dataIndex: "activated",
            render: (text, record, index) => {
                return (
                    <Popconfirm
                        title={`${record.status === "active" ? "Hide" : "Show"} character image`}
                        description={`Are you sure to ${record.status === "active" ? "hide" : "show"} this character image?`}
                        onConfirm={() => changeImageVisibility(record, record.status)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status === 'active'}
                            disabled={
                                (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                                    && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                                || (record.type === "default" && (record.status === "pending" || record.status === "rejected" || record.status === "deleted"))
                            }
                        />
                    </Popconfirm>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record, index) => {
                return (
                    <>
                        {
                            (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                            && record.type !== "default_image" && (
                                <>
                                    <Popconfirm
                                        title={`Set as default image`}
                                        description={`Are you sure to set this image to default?`}
                                        onConfirm={() => setAsDefaultImage(record)}
                                        okText="OK"
                                        cancelText="Cancel"
                                    >
                                        <Tooltip title="Set as default image">
                                            <span role="button" className='d-inline-block me-2'>
                                                <Icon name="check-circle" color="#1abc9c" size={16} />
                                            </span>
                                        </Tooltip>
                                    </Popconfirm>
                                </>
                            )
                        }
                        {
                            (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) &&
                            record.type !== "default" && (
                                <>
                                    <Popconfirm
                                        title={`Delete character image`}
                                        description={`Are you sure to delete this character image?`}
                                        onConfirm={() => triggerDeletecharacterImage(record)}
                                        okText="OK"
                                        cancelText="Cancel"
                                    >
                                        <Tooltip title="Delete character image">
                                            <span role="button" className='d-inline-block me-2'>
                                                <Icon name="trash-2" color="red" size={16} />
                                            </span>
                                        </Tooltip>
                                    </Popconfirm>
                                </>
                            )
                        }
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        if (!characterDetail) {
            return;
        }
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (!characterDetail) {
            return;
        }
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            const response = await getCharacterImages(characterDetail.storyId, characterDetail.id, searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setCharacterImages(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }

    const changeImageVisibility = async (characterImage: CharacterImageModel, currentStatus: string) => {
        const response = await updateCharacterImage(characterDetail.storyId, characterImage.characterId, characterImage.id, { status: currentStatus == "inactive" ? "active" : "inactive" });
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: (currentStatus === "activated" ? 'Deactivate' : 'Activate') + ' character image successfully!',
                placement: 'topRight'
            });
            search();
        }
    }

    const setAsDefaultImage = async (characterImage: CharacterImageModel) => {
        const response = await updateCharacterImage(characterDetail.storyId, characterImage.characterId, characterImage.id, { type: "default_image" });
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: 'Set character default image successfully!',
                placement: 'topRight'
            });
            search();
        }
    }

    const triggerDeletecharacterImage = async (characterImage: CharacterImageModel) => {
        const response = await deleteCharacterImage(characterDetail.storyId, characterImage.characterId, characterImage.id);
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: 'Set character default image successfully!',
                placement: 'topRight'
            });
            search();
        }
    }

    return (
        <Drawer
            title={"character images"}
            className='character-drawer'
            width={750}
            onClose={() => { setdDrawerVisibility(false) }}
            open={drawerVisibility}
            closeIcon={false}
        >
            {contextHolder}
            <Form layout="vertical" autoComplete='off' form={form}>
                <Row gutter={16} className='d-flex justify-content-center'>
                    <Form.Item
                        name="image"
                        label="Image"
                    >
                        <Upload
                            listType="picture-card"
                            fileList={images}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            multiple={false}
                            accept="image/png, image/jpeg, image/jpg"
                        >
                            {images.length >= 5 ? null : uploadButton}
                        </Upload>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Form.Item>
                </Row>
                <Row gutter={16} className='d-flex justify-content-center'>
                    <AntButton onClick={uploadImages} type="primary" className='me-3' disabled={!submittables} loading={loadingBtn}>
                        Upload
                    </AntButton>
                    <AntButton onClick={onClose}>Cancel</AntButton>
                </Row>
            </Form>

            <Row className='mt-5 mb-1'>
                <Col>
                    <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                        search();
                    }}>
                        <i className="mdi mdi-refresh me-1"></i> Refresh
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className='w-100'>
                    <Table
                        loading={searchLoading}
                        rowKey={"noNumber"}
                        className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                        dataSource={characterImages}
                        columns={columns}
                        pagination={false}
                    />

                    {characterImages?.length !== 0 && (
                        <CustomPagination
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            totalItem={totalRecord}
                            setPageSize={setPageSize}
                            setPageIndex={setPageIndex}
                        />
                    )}
                </Col>
            </Row>
        </Drawer>
    )

}

export default CharacterImageDrawer;
