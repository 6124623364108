import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const LOGIN_ENDPOINT = 'api/v1/admin/login';

// account
function login(params: { username: string; password: string }) {
  return api.create(`${baseUrl}/${LOGIN_ENDPOINT}`, params);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
