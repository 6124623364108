import { APICore } from "./apiCore";
import {ChatRequest} from "../../model/Chat";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const CONTINUATION_ENDPOINT = 'api/v1/admin/conversations';


function continuations(episodeId: string, data: ChatRequest) {
    return api.create(`${baseUrl}/${CONTINUATION_ENDPOINT}/${episodeId}/continuations`, data);
}


export {
    continuations
};

