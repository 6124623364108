import {
    Button as AntdButton,
    Col as AntdCol,
    Row as AntdRow,
    Form,
    Input,
    Select,
    Table,
    notification, Popconfirm, Tooltip, DatePicker,
} from "antd";
import {Option} from "antd/es/mentions";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import CustomPagination from "../../components/CustomPagination";
import PageTitle from "../../components/PageTitle";
import {searchLogErrorAi} from "../../helpers/api/logErrorAi";
import { useNavigate } from 'react-router-dom';
import {Icon} from "ts-react-feather-icons";

const { RangePicker } = DatePicker;

interface LogErrorModel {
    key: React.Key;
    id: string;
    userId: string;
    episodeId: string;
    responseRawBody: string;
    createdAt: Date;

}

const LogErrorAi = () => {
    const navigate = useNavigate(); // Lấy đối tượng navigate

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [logErrorAis, setLogErrorAis] = useState<LogErrorModel[]>([]);

    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);



    const columns: ColumnsType<LogErrorModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Response Raw Body",
            dataIndex: "responseRawBody",
            render: (text, record, index) => {
                const parsedBody = JSON.parse(record.responseRawBody);
                
                const getBooleanFields = (obj: any, prefix: string = ''): Array<[string, boolean]> => {
                    let booleanFields: Array<[string, boolean]> = [];
                    for (const [key, value] of Object.entries(obj)) {
                        const newKey = prefix ? `${prefix}.${key}` : key; // Tạo key mới cho các trường lồng nhau
                        if (typeof value === 'boolean') {
                            booleanFields.push([newKey, value]);
                        } else if (typeof value === 'object' && value !== null) {
                            booleanFields = booleanFields.concat(getBooleanFields(value, newKey)); // Gọi đệ quy
                        }
                    }
                    return booleanFields;
                };

                const booleanFields = getBooleanFields(parsedBody);

                return (
                    <div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    {booleanFields.map(([key]) => (
                                        <th key={key} style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {booleanFields.map(([_, value]) => (
                                        <td key={value ? 'true' : 'false'} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                            {value ? 'True' : 'False'}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            }
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (text, record, index) => {
                return (
                    <Tooltip title="Detail">
                  <span role="button" onClick={() => {
                      navigate(`/error_ai_logs/${record.id}`)}}>
                    <Icon name="eye" color="#0d6efd" size={16}/>
                  </span>
                    </Tooltip>
                );
            }
        }
    ];

    useEffect(() => {
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        if (formValueObj?.searchingDate) {
            searchObj["fromDate"] = new Date(formValueObj?.searchingDate[0]).toISOString();
            searchObj["toDate"] = new Date(formValueObj?.searchingDate[1]).toISOString();
        }
        if (formValueObj?.issueType) {
            searchObj["issueType"] = formValueObj?.issueType;
        }
        try {
            const response = await searchLogErrorAi(searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setLogErrorAis(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }



    return (
        <>
            {contextHolder}
            <PageTitle
                breadCrumbItems={[
                    {label: "Log error AI", path: "/error_ai_logs"},
                ]}
                title={"Log error AI"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form layout="vertical" autoComplete='off' form={form}>
                                <AntdRow gutter={16}>
                                    <AntdCol span={12}>
                                        <Form.Item
                                            name="searchingDate"
                                            label="Date"
                                        >
                                            <RangePicker className="w-100" showTime />
                                        </Form.Item>
                                    </AntdCol>
                                    <AntdCol span={12}>
                                        <Form.Item
                                            name="issueType"
                                            label="Issue Type"
                                        >
                                            <Select
                                                placeholder="Select issue type"
                                                allowClear
                                            >
                                                <Option value="sexual">Sexual</Option>
                                                <Option value="repeated">Repeated</Option>
                                                <Option value="invalidRole">Invalid Role</Option>
                                            </Select>

                                        </Form.Item>
                                    </AntdCol>
                                </AntdRow>
                                <AntdRow className="justify-content-center">
                                    <AntdCol>
                                        <AntdButton type="primary" className="me-1" htmlType="submit"
                                                    loading={searchLoading}
                                                    onClick={() => {
                                                        if (pageIndex === 1) {
                                                            search();
                                                            return;
                                                        }
                                                        setPageIndex(1);
                                                    }}
                                        >
                                            Search
                                        </AntdButton>
                                        <AntdButton type="default" loading={searchLoading} onClick={() => {
                                            form.resetFields();
                                            if (pageIndex === 1) {
                                                search();
                                                return;
                                            }
                                            setPageIndex(1);
                                        }}>
                                            Reset
                                        </AntdButton>
                                    </AntdCol>
                                </AntdRow>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={logErrorAis}
                                columns={columns}
                                pagination={false}
                            />

                            {logErrorAis?.length !== 0 && (
                                <CustomPagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalItem={totalRecord}
                                    setPageSize={setPageSize}
                                    setPageIndex={setPageIndex}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default LogErrorAi;
