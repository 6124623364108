import { Button, Form, Input, Popconfirm, Select, Table, Tooltip, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Option } from 'antd/es/mentions';
import React, { useEffect, useState } from 'react';
import { Icon } from "ts-react-feather-icons";

interface CharacterProperty {
    key: string;
    noNumber: number;
    propertyName: string;
    dataType: string;
    value: any;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'select' | 'text';
    record: CharacterProperty;
    index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'select' ? (
        <Select placeholder="Please select data type" >
            <Option value="array">Array of values</Option>
            <Option value="value">Single value</Option>
        </Select>
    ) : inputType === 'text' ? <Input /> : <TextArea rows={2} maxLength={5000} />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const CharacterProperty = ({
    properties,
    setProperties
}: any) => {

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!properties) {
            return;
        }
        setCount(properties.length);
    }, [properties]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record: CharacterProperty) => record.key === editingKey;

    const edit = (record: Partial<CharacterProperty> & { key: React.Key }) => {
        form.setFieldsValue({ name: '', age: '', address: '', ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as CharacterProperty;

            const newData = [...properties];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setProperties(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setProperties(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const defaultColumns = [
        {
            title: 'No.',
            dataIndex: 'noNumber',
            width: 10,
        },
        {
            title: 'Property name',
            dataIndex: 'propertyName',
            editable: true,
            inputType: 'text',
            width: 180,
        },
        {
            title: 'Data type',
            dataIndex: 'dataType',
            editable: true,
            inputType: 'select',
            width: 160,
            render: (_: any, record: CharacterProperty) => {
                return (
                    <span>
                        {record.dataType === 'array' ? 'Arrays of objects' : 'Single value'}
                    </span>
                )
            },
        },
        {
            title: 'Value',
            dataIndex: 'value',
            editable: true,
            inputType: ''
        },
        {
            title: 'Action',
            width: 20,
            dataIndex: 'action',
            render: (_: any, record: CharacterProperty) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                            <Icon name="check" color="#198754" size={16} />
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <span role="button">
                                <Icon name="x" color="#fd7e14" size={16} />
                            </span>
                        </Popconfirm>
                    </span>

                ) : (
                    <>
                        <Tooltip title="Edit">
                            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                <Icon name="edit-2" color="#0d6efd" size={16} />
                            </Typography.Link>
                        </Tooltip>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Tooltip title="Delete">
                                <span role="button" className='d-inline-block ms-1'>
                                    <Icon name="trash-2" color="#dc3545" size={16} />
                                </span>
                            </Tooltip>
                        </Popconfirm>
                    </>
                )

            },
        },
    ];

    const handleAdd = () => {
        const newData: CharacterProperty = {
            key: count + 1 + "",
            noNumber: count + 1,
            propertyName: `<property name>`,
            dataType: `value`,
            value: null
        };
        setProperties([...properties, newData]);
        setCount(count + 1);
    };

    const handleDelete = (key: React.Key) => {
        const newData = properties
            .filter((item: any) => item.key !== key)
            .map((item: any, index: number) => {
                return { ...item, key: index + 1, noNumber: index + 1 };
            });
        setProperties(newData);
    };


    const mergedColumns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: CharacterProperty) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <div>
            <Form form={form} component={false}>
                <Button onClick={handleAdd} type="dashed" style={{ marginBottom: 16 }}>
                    Add a property
                </Button>
                <br />
                <span className='d-inline-block my-1' style={{ color: '#6f42c1' }}>If data type is <span className='text-danger'>an array of value</span>, each element of array is seperated by <span className='text-danger'>a semicolon</span>. For example: <pre className='text-danger'>"value1;value2;value3"</pre></span>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={properties}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </div>
    );

}

export default CharacterProperty;
