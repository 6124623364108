import {Popconfirm, Switch, Tooltip, notification, Table, Image} from "antd";
import {ColumnsType} from "antd/es/table";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {Icon} from "ts-react-feather-icons";
import PageTitle from "../../components/PageTitle";
import {ActionType} from "../../constants/actionType";
import {UserRole} from "../../constants/userRole";
import {
    deleteStoryGalleryItem,
    getStoryGalleryDetail, getStoryGalleryItemDetail,
    getStoryGalleryItems,
    updateStoryGalleryItem, updateStoryGalleryOrder
} from "../../helpers/api/story";
import {useUser} from "../../hooks";
import User from "../../model/User";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import CustomPagination from "../CustomPagination";
import StoryGalleryItemDrawer from "./StoryGalleryItemDrawer";

interface GalleryItemModel {
    key: React.Key;
    id: string;
    imageUrl: string;
    status: string;
    index: number;
    createdAt: Date;
    modifiedAt: Date;
    createdBy: string;
    modifiedBy: string;
    storyGalleryId: string;
}

const StoryGalleryDetail = () => {
    const {storyId, galleryId} = useParams();
    const [storyGalleryDetail, setStoryGalleryDetail] = useState<any>(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [itemImages, setItemImages] = useState<any[]>([]);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const [galleryItemDetail, setGalleryItemDetail] = useState<any>(null);
    const [actionType, setActionType] = useState<string>("");
    const [galleryItemDrawerVisibility, setGalleryItemDrawerVisibility] = useState<boolean>(false);

    const currentLoggedInUser = useUser().user as User || null;
    const [api, contextHolder] = notification.useNotification();
    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    useEffect(() => {
        if (!storyId) {
            return;
        }
        if (!galleryId) {
            return;
        }
        getStoryGalleryDetailById(storyId, galleryId);
        search();
    }, [storyId, galleryId]);

    const getStoryGalleryDetailById = async (storyId: string, galleryId: string) => {
        try {
            const res = await getStoryGalleryDetail(storyId, galleryId);
            setStoryGalleryDetail(res.data.data);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }


    const changeImageVisibility = async (galleryItem: GalleryItemModel, currentStatus: string) => {
        if (!storyId || !galleryItem.id) {
            return;
        }
        const requestModel: any = {
            status: currentStatus === "active" ? "inactive" : "active"
        }
        const requestBody: any = {
            data: JSON.stringify(requestModel)
        }
        const response = await updateStoryGalleryItem(storyId, galleryItem.storyGalleryId, galleryItem.id, requestBody);
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: (currentStatus === "active" ? 'Deactivate' : 'Activate') + ' galleryItem successfully!',
                placement: 'topRight'
            });
        }
        search();
    }


    const [isOrderMode, setIsOrderMode] = useState<boolean>(false);
    const type = 'DraggableBodyRow';


    const DraggableBodyRow = ({index, moveRow, className, style, ...restProps}: {
        index: number,
        moveRow: (dragIndex: number, hoverIndex: number) => void,
        className: string,
        style: React.CSSProperties
    }) => {
        const ref = useRef<HTMLTableRowElement>(null);
        const [{isOver, dropClassName}, drop] = useDrop({
            accept: type,
            collect: monitor => {
                const {index: dragIndex} = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: (item: { index: number }) => {
                moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: {index},
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));

        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{cursor: 'move', ...style}}
                {...restProps}
            />
        );
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = itemImages[dragIndex];
            const newData = [...itemImages];
            newData.splice(dragIndex, 1);
            newData.splice(hoverIndex, 0, dragRow);
            setItemImages(newData);
        },
        [itemImages],
    );

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const columns: ColumnsType<GalleryItemModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Image",
            dataIndex: "name",
            render: (text, record, index) => {
                return (

                    <span>
                            <Image
                                width={50}
                                src={record.imageUrl}
                            />
                        </span>

                )
            },
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Created by",
            dataIndex: "createdBy",
        },
        {
            title: "Modified by",
            dataIndex: "modifiedBy",
        },
        {
            title: "Index",
            dataIndex: "index",
        },
        {
            title: "Published?",
            dataIndex: "activated",
            render: (text, record, index) => {
                return (
                    <Popconfirm
                        title={`${record.status === "active" ? "Hide" : "Show"} gallery item`}
                        description={`Are you sure to ${record.status === "active" ? "hide" : "show"} this gallery item?`}
                        onConfirm={() => changeImageVisibility(record, record.status)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status === 'active'}
                                disabled={
                                    (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                                        && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                                }
                        />
                    </Popconfirm>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record, index) => {
                return (
                    <>
                        {
                            (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
                                <>
                                    <Tooltip title="Update">
                                <span role="button"
                                      onClick={() => triggerUpdate(record.id)}
                                      className="d-inline-block ms-1"
                                >
                                    <Icon name="edit-2" color="#0d6efd" size={16}/>
                                </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <Popconfirm
                                            title={`Delete episode image`}
                                            description={`Are you sure to delete this gallery item?`}
                                            onConfirm={() => triggerDelete(record)}
                                            okText="OK"
                                            cancelText="Cancel"
                                        >

                                            <span role="button" className='d-inline-block me-2'>
                                                <Icon name="trash-2" color="red" size={16}/>
                                            </span>

                                        </Popconfirm>
                                    </Tooltip>
                                </>
                            )
                        }
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        if (!storyId || !galleryId) {
            return;
        }
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (!storyId || !galleryId) {
            return;
        }
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            if (!storyId || !galleryId) {
                return;
            }
            const response = await getStoryGalleryItems(storyId, galleryId, searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setItemImages(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }


    const triggerUpdate = async (storyGalleryItemId: string) => {
        try {
            if (!storyGalleryItemId || storyGalleryItemId?.trim()?.length === 0) {
                return;
            }
            const story = await getStoryGalleryItemDetail(storyGalleryDetail.storyId, storyGalleryDetail.galleryId, storyGalleryItemId);
            setGalleryItemDetail(story.data.data);
            setActionType(ActionType.UPDATE);
            setGalleryItemDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const triggerDelete = async (storyGalleryItem: GalleryItemModel) => {
        try {
            if (!storyGalleryItem.id || storyGalleryItem.id?.trim()?.length === 0) {
                return;
            }
            if (storyGalleryItem.status === "active") {
                showError("Cannot delete active gallery item!");
                return;
            }
            const response = await deleteStoryGalleryItem(storyGalleryDetail.storyId, storyGalleryDetail.id, storyGalleryItem.id);
            if (response.status === 200) {
                api.success({
                    message: `Successfull notification`,
                    description: 'Delete gallery item successfully!',
                    placement: 'topRight'
                });
                search();
                return;
            }
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const saveOrder = async () => {
        try {
            const requestModel: any = [];
            itemImages.forEach((item, index) => {
                requestModel.push({
                    id: item.id,
                    index: index
                });
            });
            const response = await updateStoryGalleryOrder(storyGalleryDetail.storyId, storyGalleryDetail.id, requestModel);
            if (response.status === 200) {
                api.success({
                    message: `Successfull notification`,
                    description: 'Update order successfully!',
                    placement: 'topRight'
                });
                setIsOrderMode(false);
                search();
                return;
            }
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    return (
        storyGalleryDetail && (<>
            {contextHolder}

            <StoryGalleryItemDrawer
                storyGallery={storyGalleryDetail}
                drawerVisibility={galleryItemDrawerVisibility}
                setDrawerVisibility={setGalleryItemDrawerVisibility}
                storyGalleryItemDetail={galleryItemDetail}
                setStoryGalleryItemDetail={setGalleryItemDetail}
                actionType={actionType}
                setActionType={setActionType}
                onSuccess={() => {
                    api.success({
                        message: `Successfull notification`,
                        description: 'Create character successfully!',
                        placement: 'topRight'
                    });
                    setGalleryItemDetail(null);
                    setActionType("");
                    search();
                }}
                onFailure={(errorMessage: string) => {
                    showError(errorMessage);
                }}
            />

            <PageTitle
                breadCrumbItems={[
                    {label: "Stories", path: "/stories", active: false},
                    {label: "Stories detail", path: "/stories/" + storyGalleryDetail.storyId, active: false},
                    {
                        label: "Gallery detail",
                        path: "/stories/" + storyGalleryDetail.storyId + "/galleries/" + storyGalleryDetail.id,
                        active: true
                    },

                ]}
                title={<span><span className="text-danger">{storyGalleryDetail.name}</span></span>}
            />

            <Row>
                <Col sm={6} className="mb-2">
                    <span className="fw-bold lead text-success">GALLERY ITEM</span>
                </Col>
                <Col sm={6} className="mb-2">
                    <div className="text-sm-end">
                        {isOrderMode ? (
                            <>
                                <Button variant="success" className="mb-1 py-1 me-1" onClick={saveOrder}>
                                    <i className="mdi mdi-content-save me-1"></i> Save
                                </Button>
                                <Button variant="danger" className="mb-1 py-1 me-1" onClick={() => {
                                    setIsOrderMode(false);
                                    search();
                                }}>
                                    <i className="mdi mdi-cancel me-1"></i> Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                                    search();
                                }}>
                                    <i className="mdi mdi-refresh me-1"></i> Refresh
                                </Button>
                                <Button variant="primary" className="mb-1 py-1 me-1" onClick={async () => {
                                    setActionType(ActionType.CREATE);
                                    setGalleryItemDrawerVisibility(true);
                                }}>
                                    <i className="mdi mdi-plus-circle me-1"></i> Create
                                </Button>
                                <Button variant="warning" className="mb-1 py-1 me-1" onClick={() => {
                                    setIsOrderMode(!isOrderMode);
                                }}>
                                    <i className="mdi mdi-reorder-horizontal me-1"></i> Order
                                </Button>
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className='w-100'>
                    {isOrderMode ? (
                        <DndProvider backend={HTML5Backend}>
                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={itemImages}
                                columns={columns}
                                pagination={false}
                                components={components}
                                onRow={(record, index) => {
                                    return {
                                        index: index,
                                        moveRow,
                                    } as React.HTMLAttributes<any> & React.TdHTMLAttributes<any>;
                                }}
                            />
                        </DndProvider>
                    ) : (
                        <Table
                            loading={searchLoading}
                            rowKey={"noNumber"}
                            className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                            dataSource={itemImages}
                            columns={columns}
                            pagination={false}
                        />
                    )}

                    {itemImages?.length !== 0 && (
                        <CustomPagination
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            totalItem={totalRecord}
                            setPageSize={setPageSize}
                            setPageIndex={setPageIndex}
                        />
                    )}
                </Col>
            </Row>
        </>)
    );
};

export default StoryGalleryDetail;
