import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Upload, Modal, UploadFile
} from 'antd';
import {Option} from 'antd/es/mentions';
import {useEffect, useState} from 'react';
import {ActionType} from '../../constants/actionType';
import {
    createStoryGallery,
    updateStoryGallery,
} from '../../helpers/api/story';
import {Icon} from "ts-react-feather-icons";
import {RcFile, UploadProps} from "antd/es/upload";

const storyGalleryDrawerValidation = {
    name: [
        {required: true, message: 'Name is required'},
        {max: 100, message: 'Name must be less than 100 characters'},
    ],
    status: [
        {required: true, message: 'Status is required'},
    ]
}


const StoryGalleryDrawer = ({
                                story,
                                drawerVisibility,
                                setDrawerVisibility,
                                storyGalleryDetail,
                                setStoryGalleryDetail,
                                actionType,
                                setActionType,
                                onSuccess,
                                onFailure
                            }: any) => {
    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);

    const [submittables, setSubmittable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

    const [status, setStatus] = useState<string>("inactive");


    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [images, setImages] = useState<UploadFile[]>([]);

    // images
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({fileList: newFileList}) =>
        setImages(newFileList);

    const uploadButton = (
        <div>
            {/* <PlusOutlined rev={1} /> */}
            <Icon name="plus" size={16}/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    useEffect(() => {
        form.validateFields({validateOnly: true})
            .then(
                () => {
                    if (images.length > 0) {
                    setSubmittable(true)
                    }
                    else {
                        setSubmittable(false)
                    }
                },
                () => {
                    setSubmittable(false)
                },
            );
    }, [formValueObj, images]);

    useEffect(() => {
        if (actionType === ActionType.CREATE) {
            form.resetFields();
            setSubmittable(false);
            setStatus("inactive");
            form.setFieldValue("status", "inactive");
        }
    }, [actionType]);


    useEffect(() => {
        if (!storyGalleryDetail) {
            form.setFieldValue("status", "inactive");
            form.setFieldValue("previousContinued", true);
            return;
        }
        setStatus(storyGalleryDetail.status);
        form.setFieldsValue({...storyGalleryDetail});
        if (storyGalleryDetail?.imageUrl && storyGalleryDetail?.imageUrl?.trim().length > 0) {
            const urlTemp = storyGalleryDetail?.imageUrl.split("/");
            setImages([{
                uid: "1",
                name: urlTemp[urlTemp.length - 1],
                status: 'done',
                url: storyGalleryDetail?.imageUrl
            }]);
        }

    }, [storyGalleryDetail]);


    const onClose = () => {
        form.resetFields();
        setStoryGalleryDetail(null);
        form.setFieldValue("status", "inactive");
        setSubmittable(false);
        setDrawerVisibility(false);
        setActionType("");
        setImages([]);
    }

    useEffect(() => {
        if (images.length > 0) {
            setSubmittable(true);
        }
    }, [images]);

    const saveStoryGallery = async () => {
        try {
            setLoadingBtn(true);
            form.validateFields();
            let response;
            const requestModel: any = {
                name: formValueObj.name?.trim(),
                status: status,
            }
            if (actionType === ActionType.CREATE) {
                response = await createStoryGallery(story.id, {
                    data: JSON.stringify(requestModel),
                    image: images.length > 0 ? images?.[0]?.originFileObj : null
                });
            } else {
                const requestBody: any = {};
                if (images?.[0] && images?.[0].url === storyGalleryDetail.imageUrl) {
                    requestModel["imageUrl"] = storyGalleryDetail.imageUrl;
                } else {
                    requestBody["image"] = images?.[0]?.originFileObj;
                }
                requestBody["data"] = JSON.stringify(requestModel);
                response = await updateStoryGallery(story.id, storyGalleryDetail.id, requestBody);
            }

            setLoadingBtn(false);
            if (response?.status === 200) {
                onSuccess();
                onClose();
                return;
            }
        } catch (err) {
            console.log(err);
            onFailure(err);
        }
        setLoadingBtn(false);
    }

    return (
        <Drawer
            title={(actionType === ActionType.UPDATE ? "Update" : "Create") + " a gallery"}
            className='character-drawer'
            width={750}
            onClose={() => {
                setDrawerVisibility(false)
                onClose();
            }}
            open={drawerVisibility}
            closeIcon={false}
            footer={
                <div className='d-flex justify-content-center'>
                    <Button onClick={saveStoryGallery} type="primary" className='me-3' disabled={!submittables}
                            loading={loadingBtn}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            }
        >
            <Form layout="vertical" autoComplete='off' form={form}>
                <Row gutter={16} className='d-flex justify-content-center'>
                    <Form.Item
                        name="image"
                        label="Image"
                    >
                        <Upload
                            listType="picture-card"
                            fileList={images}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            multiple={false}
                            accept="image/png, image/jpeg, image/jpg"
                        >
                            {images.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </Form.Item>
                </Row>
                <Row gutter={16} className='mb-3'>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={storyGalleryDrawerValidation.name}
                        >
                            <Input placeholder="Please enter name"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status"
                            rules={storyGalleryDrawerValidation.status}
                        >
                            <Select placeholder="Please select a status" value={status} onChange={(e) => setStatus(e)}>
                                <Option value="active">Active</Option>
                                <Option value="inactive">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>

                </Row>
            </Form>

        </Drawer>
    )

}

export default StoryGalleryDrawer;
