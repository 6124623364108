import {APICore} from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const MODEL_ENDPOINT = 'api/v1/admin/models';


function getModels() {
    return api.get(`${baseUrl}/${MODEL_ENDPOINT}`, {});
}

export {
    getModels
};