import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const LOG_ERROR_ENDPOINT = 'api/v1/admin/error_ai_logs';


function searchLogErrorAi(params: any) {
    return api.get(`${baseUrl}/${LOG_ERROR_ENDPOINT}`, params);
}

function getLogErrorAiDetail(logId: string) {
    return api.get(`${baseUrl}/${LOG_ERROR_ENDPOINT}/${logId}`, {});
}

function getEpisodeDetailWithLog(episodeId: string) {
    return api.get(`${baseUrl}/${LOG_ERROR_ENDPOINT}/episode/${episodeId}`, {});
}

function getChatMessages(params: any) {
    return api.get(`${baseUrl}/${LOG_ERROR_ENDPOINT}/messages/search`, params);
}

export {
    searchLogErrorAi,
    getLogErrorAiDetail,
    getEpisodeDetailWithLog,
    getChatMessages
};