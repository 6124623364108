import React, { useEffect, useState } from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import {getChatMessages, getEpisodeDetailWithLog, getLogErrorAiDetail} from "../../helpers/api/logErrorAi";
import {notification, Popconfirm, Switch, Table, Tooltip} from "antd";
import {ActionType} from "../../constants/actionType";
import CustomPagination from "../../components/CustomPagination";
import {ColumnsType} from "antd/es/table";
import {UserRole} from "../../constants/userRole";
import {Icon} from "ts-react-feather-icons";
import {getStoryCharacters} from "../../helpers/api/story";

interface ChatMessageModel {
    key: React.Key;
    id: string;
    userId: string;
    episodeId: string;
    role: string;
    content: string;
    createdAt: Date;

}

const LogErrorAiDetail = () => {

    const { logErrorAiId } = useParams();

    const [logErrorAi, setLogErrorAi] = useState<any>(null);
    const [api, contextHolder] = notification.useNotification();

    const [episode, setEpisode] = useState<any>(null);


    const [pageSize, setPageSize] = useState<number>(20);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [chatMessages, setChatMessages] = useState<any[]>([]);

    const [errorJson, setErrorJson] = useState<any>(null);


    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }


    useEffect(() => {
        if (!logErrorAiId) {
            return;
        }
        getLogErrorAiDetailById();
    }, [logErrorAiId]);

    const getLogErrorAiDetailById = async () => {
        try {
            const res = await getLogErrorAiDetail(logErrorAiId as string);
            setLogErrorAi(res.data.data);
            const episodeId = res.data.data.episodeId;
            const resEpisode = await getEpisodeDetailWithLog(episodeId);
            setEpisode(resEpisode.data.data);

            const parsedBody = JSON.parse(res.data.data.responseRawBody);
            setErrorJson(parsedBody);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }


    const columns: ColumnsType<ChatMessageModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Role",
            dataIndex: "role",
        },
        {
            title: "Content",
            dataIndex: "content",
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },

    ];

    useEffect(() => {
        if (!logErrorAi) {
            return;
        }
        search();
    }, [logErrorAi]);

    const loadMore = async () => {
        setPageIndex(pageIndex + 1);
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex,
            pageSize,
            episodeId: logErrorAi.episodeId,
            userId: logErrorAi.userId,
            toDate: logErrorAi.createdAt,
            fromDate: new Date(new Date(logErrorAi.createdAt).setDate(new Date(logErrorAi.createdAt).getDate() - 1)).toISOString()
        };
        try {
            const response = await getChatMessages(searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex) * pageSize + index + 1,
                    });
                });
                setChatMessages([...chatMessages, ...data]);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }



    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
            episodeId: logErrorAi.episodeId,
            userId: logErrorAi.userId,
            toDate: logErrorAi.createdAt,
            fromDate: new Date(new Date(logErrorAi.createdAt).setDate(new Date(logErrorAi.createdAt).getDate() - 1)).toISOString()
        };
        try {
            const response = await getChatMessages(searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setChatMessages(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }


    return (
        logErrorAi && (<>
            {contextHolder}

            <PageTitle
                breadCrumbItems={[
                    {label: "Log error AI", path: "/error_ai_logs", active: false},
                    { label: "Log error AI detail", path: "/error_ai_logs/" + logErrorAiId, active: true },
                ]}
                title="Log error AI detail"
            />

            <Row>

                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">INFOMATION</span>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6} className="mb-2">
                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">User ID:</span>
                                        <span>{logErrorAi.userId}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">Created at:</span>
                                        <span>{logErrorAi.createdAt ? new Date(logErrorAi.createdAt).toLocaleString() : ''}</span>
                                    </div>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">Episode ID:</span>
                                        <span>{logErrorAi.episodeId}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">Episode name:</span>
                                        <span>{episode?.name}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">LOG</span>
                                </Col>
                            </Row>

                            {
                                errorJson && (
                                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {JSON.stringify(errorJson, null, 2)}
        </pre>
                                )
                            }
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">CHAT MESSAGES( {totalRecord} message )</span>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <div className="text-sm-end">
                                        <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                                            loadMore();
                                        }}>
                                            <i className="mdi mdi-refresh-circle me-1"></i> Load more
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={chatMessages}
                                columns={columns}
                                pagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>)
    );
};

export default LogErrorAiDetail;
