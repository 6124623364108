import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const STORY_ENDPOINT = 'api/v1/admin/stories';

function searchStory(params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}`, params);
}

function createStory(data: any) {
  return api.createWithFile(`${baseUrl}/${STORY_ENDPOINT}`, data);
}

function updateStory(storyId: string, data: any) {
  return api.updateWithFilePut(`${baseUrl}/${STORY_ENDPOINT}/${storyId}`, data);
}

function updateStoryActivation(storyId: string, data: any) {
  return api.updateWithFilePut(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/activations`, data);
}

function getStoryDetail(storyId: string) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}`, {});
}

function deleteStory(storyId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}`);
}


// episodes
function getEpisodes(storyId: string, params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes`, params);
}

function createEpisode(storyId: string, data: any) {
  return api.create(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes`, data);
}

function getEpisodeDetail(storyId: string, episodeId: string) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}`, {});
}

function updateEpisode(storyId: string, episodeId: string, data: any) {
  return api.update(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}`, data);
}

function deleteEpisode(storyId: string, episodeId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}`);
}

function uploadEpisodeImages(storyId: string, episodeId: string, data: FormData) {
  return api.createWithFileInFormData(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/images`, data);
}

function getEpisodeImages(storyId: string, episodeId: string, params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/images`, params);
}

function updateEpisodeImage(storyId: string, episodeId: string, episodeImageId: string, params: any) {
  return api.update(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/images/${episodeImageId}`, params);
}

function deleteEpisodeImage(storyId: string, episodeId: string, episodeImageId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/images/${episodeImageId}`);
}

// characters
function getStoryCharacters(storyId: string, params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters`, params);
}

function createCharacter(storyId: string, data: any) {
  return api.create(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters`, data);
}

function deleteCharacter(storyId: string, characterId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${characterId}`);
}

function getCharacterDetail(storyId: string, characterId: string) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${characterId}`, {});
}

function updateCharacter(storyId: string, characterId: string, model: any) {
  return api.update(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${characterId}`, model);
}

function uploadCharacterImages(storyId: string, episodeId: string, data: FormData) {
  return api.createWithFileInFormData(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${episodeId}/images`, data);
}

function getCharacterImages(storyId: string, episodeId: string, params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${episodeId}/images`, params);
}

function updateCharacterImage(storyId: string, episodeId: string, episodeImageId: string, params: any) {
  return api.update(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${episodeId}/images/${episodeImageId}`, params);
}

function deleteCharacterImage(storyId: string, episodeId: string, episodeImageId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/characters/${episodeId}/images/${episodeImageId}`);
}

function episodesActivation(storyId: string, episodeId: string) {
  return api.create(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/activation`, {});
}

function episodesReset(storyId: string, episodeId: string) {
  return api.create(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/reset`, {});
}

function episodesEvaluation(storyId: string, episodeId: string, data: any) {
  return api.create(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/episodes/${episodeId}/evaluation`, data);
}

// gallery
function getStoryGalleries(storyId: string, params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries`, params);
}

function createStoryGallery(storyId: string, data: any) {
  return api.createWithFile(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries`, data);
}

function deleteStoryGallery(storyId: string, galleryId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}`);
}

function getStoryGalleryDetail(storyId: string, galleryId: string) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}`, {});
}

function updateStoryGallery(storyId: string, galleryId: string, data: any) {
  return api.updateWithFilePut(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}`, data);
}

function updateStoryGalleryOrder(storyId: string, galleryId: string, data: any) {
  return api.update(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}/order`, data);
}

function createStoryGalleryItem(storyId: string, galleryId: string, data: any) {
  return api.createWithMultipleFiles(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}/items`, data);
}

function getStoryGalleryItems(storyId: string, galleryId: string, params: any) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}/items`, params);
}

function getStoryGalleryItemDetail(storyId: string, galleryId: string, itemId: string) {
  return api.get(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}/items/${itemId}`, {});
}

function updateStoryGalleryItem(storyId: string, galleryId: string, itemId: string, data: any) {
  return api.updateWithFilePut(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}/items/${itemId}`, data);
}

function deleteStoryGalleryItem(storyId: string, galleryId: string, itemId: string) {
  return api.delete(`${baseUrl}/${STORY_ENDPOINT}/${storyId}/galleries/${galleryId}/items/${itemId}`);
}



export {
  createCharacter, createEpisode, createStory, deleteCharacter, deleteCharacterImage, deleteEpisode, deleteEpisodeImage, deleteStory,
  getCharacterDetail, getCharacterImages, getEpisodeDetail, getEpisodeImages, getEpisodes, getStoryCharacters, getStoryDetail,
  searchStory, updateCharacter, updateCharacterImage, updateEpisode, updateEpisodeImage,
  updateStory, updateStoryActivation, uploadCharacterImages, uploadEpisodeImages,episodesActivation, episodesEvaluation, episodesReset,
    getStoryGalleries, createStoryGallery, deleteStoryGallery, getStoryGalleryDetail, updateStoryGallery, getStoryGalleryItemDetail,
    updateStoryGalleryOrder, createStoryGalleryItem, getStoryGalleryItems, updateStoryGalleryItem, deleteStoryGalleryItem
};

