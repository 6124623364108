import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Option } from 'antd/es/mentions';
import { useEffect, useState } from 'react';
import { ActionType } from '../../constants/actionType';
import { createCharacter, updateCharacter } from '../../helpers/api/story';
import './CharacterDrawer.scss';
import CharacterProperty from './CharacterProperty';

const characterDrawerValidation = {
    name: [
        { required: true, message: 'Name is required' },
        { max: 100, message: 'Name must be less than 100 characters' },
    ],
    status: [
        { required: true, message: 'Status is required' },
    ],
    type: [
        { required: true, message: 'Type is required' },
    ],
    generalInformation: [
        { required: true, message: 'General information is required' },
        { max: 5000, message: 'General information must be less than 5000 characters' },
    ]
}

const CharacterDrawer = ({
    story,
    drawerVisibility,
    setdDrawerVisibility,
    characterDetail,
    setCharacterDetail,
    characters,
    actionType,
    setActionType,
    onSuccess,
    onFailure
}: any) => {

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);

    const [submittables, setSubmittable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [properties, setProperties] = useState<any[]>([]);
    const [characterStatus, setCharacterStatus] = useState<string>("inactive");
    const [characterType, setCharacterType] = useState<string>("ai_character");

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(
                () => { setSubmittable(true) },
                () => { setSubmittable(false) },
            );
    }, [formValueObj]);

    useEffect(() => {
        if (actionType === ActionType.CREATE) {
            form.resetFields();
            setCharacterStatus("inactive");
            form.setFieldValue("status", "inactive");
            form.setFieldValue("type", "ai_character");
            setProperties([{
                key: "1",
                noNumber: "1",
                propertyName: "fullDescription",
                dataType: "value",
                value: ""
            }]);
        }
    }, [actionType]);

    useEffect(() => {
        if (!characterDetail) {
            form.setFieldValue("status", "inactive");
            form.setFieldValue("type", "ai_character");
            return;
        }
        let property = JSON.parse(characterDetail.description);
        let count = 0;
        const tempProperties = [];
        for (let key in property) {
            key = key.replace("<property name>", "").trim();
            if (key.length === 0) {
                continue;
            }
            count++;
            const value = property[key];
            if (Array.isArray(value)) {
                tempProperties.push({
                    key: count + "",
                    noNumber: count,
                    propertyName: key,
                    dataType: `array`,
                    value: value.join(";")
                });
                continue;
            }
            tempProperties.push({
                key: count + "",
                noNumber: count,
                propertyName: key,
                dataType: `value`,
                value: value
            });
        }
        setProperties(tempProperties);
        setCharacterStatus(characterDetail.status);
        setCharacterType(characterDetail.type);
        form.setFieldsValue({ ...characterDetail });
    }, [characterDetail]);
    
    const onClose = () => {
        form.resetFields();
        setCharacterDetail(null);
        setCharacterStatus("inactive");
        form.setFieldValue("status", "inactive");
        form.setFieldValue("type", "ai_character");
        setCharacterType("ai_character");
        setSubmittable(false);
        setdDrawerVisibility(false);
        setActionType("");
        setProperties([]);
    }

    const saveCharacter = async () => {
        try {
            setLoadingBtn(true);
            let response;
            let characterProperty: any = {};
            if (properties && properties?.length > 0) {
                for (let i = 0; i < properties.length; i++) {
                    const item = properties[i];
                    const key = item.propertyName.trim().replaceAll("<property name>", "").trim();
                    if (key.length === 0) {
                        continue;
                    }
                    let value = item.value?.trim();
                    if (value?.length === 0) {
                        onFailure("Please complete character properties. There are some properties doesn't have value");
                        setLoadingBtn(false);
                        return;
                    }
                    if (item.dataType == 'array') {
                        value = item.value.split(";")
                            .map((t: string) => t ? t.trim() : null)
                            .filter((t: string) => t !== null && t !== undefined && t !== '');
                    }
                    characterProperty[item.propertyName] = value;
                }
            }
            const requestModel = {
                name: formValueObj.name?.trim(),
                generalInformation: formValueObj.generalInformation?.trim(),
                status: characterStatus,
                type: characterType,
                properties: characterProperty
            }
            if (characterStatus === "active" && characterType === "user_character") {
                const tempChars = characters.map((c: any) => {
                    if (c.id === characterDetail?.id) return { ...characterDetail, ...requestModel }
                    return c;
                });
                if (actionType === ActionType.CREATE) {
                    tempChars.push(requestModel);
                }
                const count = tempChars
                    .filter((c: any) => c.status === "active" && (actionType === ActionType.CREATE || c.id !== characterDetail?.id) && c.type === "user_character")
                    .length;
                if (count > 1) {
                    onFailure("There must be only 1 active user character at a time");
                    setLoadingBtn(false);
                    return;
                } else {
                    if (requestModel.name !== "{{user.name}}") {
                        onFailure("User character must be '{{user.name}}', please update the name following this rule and activate character!");
                        setLoadingBtn(false);
                        return;
                    }
                }
            }
            if (actionType === ActionType.CREATE) {
                response = await createCharacter(story.id, requestModel);
            } else {
                response = await updateCharacter(story.id, characterDetail.id, requestModel);
            }

            setLoadingBtn(false);
            if (response?.status === 200) {
                onSuccess();
                onClose();
                return;
            }
        } catch (err) {
            console.log(err);
            onFailure(err);
        }
        setLoadingBtn(false);
    }

    return (
        <Drawer
            title={(actionType === ActionType.UPDATE ? "Update" : "Create") + " character"}
            className='character-drawer'
            width={850}
            onClose={() => { setdDrawerVisibility(false) }}
            open={drawerVisibility}
            closeIcon={false}
            footer={
                <div className='d-flex justify-content-center'>
                    <Button onClick={saveCharacter} type="primary" className='me-3' disabled={!submittables} loading={loadingBtn}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            }
        >
            <Form layout="vertical" autoComplete='off' form={form}>
                <Row gutter={16} className='mb-3'>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={characterDrawerValidation.name}
                        >
                            <Input placeholder="Please enter name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className='mb-3'>
                    <Col span={12}>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={characterDrawerValidation.type}
                        >
                            <Select placeholder="Please select a type" value={characterType} onChange={(e) => setCharacterType(e)}>
                                <Option value="user_character">User character</Option>
                                <Option value="ai_character">AI character</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status"
                            rules={characterDrawerValidation.status}
                        >
                            <Select placeholder="Please select a status" value={characterStatus} onChange={(e) => setCharacterStatus(e)}>
                                <Option value="active">Active</Option>
                                <Option value="inactive">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="generalInformation"
                            label="Short description"
                            rules={characterDrawerValidation.generalInformation}
                        >
                            <TextArea rows={4} placeholder="Enter short description" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="customProperties"
                            label="Custom propeties"
                        >
                            <CharacterProperty properties={properties} setProperties={setProperties} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )

}

export default CharacterDrawer;
