import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const TAG_ENDPOINT = 'api/v1/admin/tags';


function getTags(params: any) {
    return api.get(`${baseUrl}/${TAG_ENDPOINT}`, params);
}

function createTag(data: any) {
    return api.create(`${baseUrl}/${TAG_ENDPOINT}`, data);
}

function deleteTag(tagId: string) {
    return api.delete(`${baseUrl}/${TAG_ENDPOINT}/${tagId}`);
}

function getTagDetail(tagId: string) {
    return api.get(`${baseUrl}/${TAG_ENDPOINT}/${tagId}`, {});
}

function updateTag(tagId: string, data: any) {
    return api.update(`${baseUrl}/${TAG_ENDPOINT}/${tagId}`, data);
}

export { getTags, createTag, deleteTag, getTagDetail, updateTag };